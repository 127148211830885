@import "../../styles/variables";

.i-Form_Text {
	font-size: 1.875rem;
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.i-Form_Text-noSub {
	margin-bottom: 3rem;
}

.i-Form_Description {
	font-size: 0.875rem;
	margin-top: 0.5rem;
	margin-bottom: 3rem;
	color: $text-light;
	line-height: 1.25rem;
}