// Form
.i-FormSection {
	display: flex;
	justify-content: center;
}

.i-Form {
	max-width: $data-input-maxWidth;
	width: 100%;

	.i-Form-info {
		max-width: $data-input-maxWidth;

		.i-Button-form {
			float: right;
		}
	}
}

.i-Form_Element-marginTop {
	margin-top: 1.5rem;
}

.i-Form_ButtonGroup {
	display: flex;
	justify-content: space-between;
	margin-top: 56px;
}

.i-icon-s {
	max-width: 1rem;
	max-height: 1rem;
}

.i-Form-alert {
	padding: 0;
	list-style-type: none;
	margin: 0;
}

.i-Form-p {
  white-space: break-spaces;
}

.i-Centered-Form {
	max-width: 615px;
	width: 100%;
	background-color: $white;
	padding: 40px;
	border-radius: 12px;

	.i-Form-info {
		//max-width: 866px;

		.i-Button-form {
			float: right;
		}

		p {
			font-size: 0.875rem;
		}
	}
}

.i-Centered-Form_ButtonGroup {
	display: flex;
	justify-content: right;
	margin-top: 56px;
}