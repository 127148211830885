@import "../../styles/variables";
.i-Policy_FooterNote {
	margin-top: 3rem;
	border-top: 1px solid $ui-gray-dark;
	background-color: $white;
	padding: 1.3rem 0;
	// Medium devices (tablets, between 768px and 992px)
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
	}
	// Small devices (less than 767px)
	@media screen and (max-width: 767px) {
		width: 100%;
	}
}
.i-Policy_FooterNote-right {
	color: $ui-blue;
}
.i-Policy_FooterNote-text {
	display: flex;
	justify-content: space-between;
	font-weight: 400;
	color: $text-light;
	margin: 0;
	font-size: 0.75rem;
	padding: 0 1.25rem;
}
.i-Policy_FooterNote-policy,
.i-Policy_FooterNote-term {
	cursor: pointer;
	&:hover {
		color: $ui-blue-dark;
	}
}
.i-Policy_FooterNote-dot {
	color: $text-placeholder;
	font-weight: 900;
}
.i-Policy_FooterNote-policy.i-Policy_FooterNote-noHover {
	cursor: auto;
	&:hover {
		color: $text-light;
	}
}
.i-Policy_FooterNote-term {
	margin-right: .5rem;
}
.i-Policy_FooterNote-policy {
	margin-left: .5rem;
}