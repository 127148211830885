// Alert
.i-Alert {
	margin-bottom: 1.5rem;
}

.i-Alert-Column {
	align-items: flex-start;
	flex-direction: column;
	padding: 1.5rem;

	div:first-of-type {
		margin-top: 0.5rem;
		margin-right: 0;
	}
}