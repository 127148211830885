@import "../../styles/variables";

// Navigation
.i-NavSection {
  margin-bottom: 6rem;
}
.i-TopNav {
  align-items: center;
  position: relative;
  z-index: 100;
  display: flex;
  min-height: 3.5rem;
}
.i-TopNav_Logo {
  margin-top: 3.2rem;
  margin-left: 3.7rem;
}

.i-Snow-logo {
  width: 106px;
  height: 36px;
  color: #101E2C;
}