@import "../../styles/variables";

.i-Form_LinkAndIcon {
	display: flex;
	align-items: center;
	color: $text-light;
	cursor: pointer;
}

.i-Form_LinkAndIcon-link {
		font-size: 0.875rem;
		font-weight: 500;
	a{
		color: $text-light;
		text-decoration: none;
	}
}

.i-Form_LinkAndIcon-icon {
	margin-right: .5rem;
}