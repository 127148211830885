/*------------------------------------
    BASE OBJECT - ICON
------------------------------------*/
.p-Icon {
    fill: currentColor;
    flex-shrink: 0;
}
/*------------------------------------
    OBJECT VARIANTS
------------------------------------*/
.p-Icon-xs {
    height: .75rem;
    width: .75rem;
}
.p-Icon-s {
    height: 1rem;
    width: 1rem;
}
.p-Icon-xm {
    height: 1.2rem;
    width: 1.2rem;     
}
.p-Icon-m {
    height: 1.5rem;
    width: 1.5rem;     
}
.p-Icon-l {
    height: 2rem;
    width: 2rem;    
}
.p-Icon-xl {
    height: 3rem;
    width: 3rem;
}
.p-Icon-xxl {
    height: 5.5rem;
    width: 5.5rem;
}
/*------------------------------------
    BASE OBJECT - ICON HOLDER
------------------------------------*/
.p-IconHolder {
    align-items: center;
    background-color: $ui-blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: 0;
}