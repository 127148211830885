@import "./styles/variables";


body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-Container,
.i-PageContent {
  height: 100vh;
}

.idp-App {
  background-color: $white;
  min-height: 100vh;
  font-size: 14px;
  color: $text-dark;
  position: relative;
}

.i-PageContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.i-PageSide-left {
  overflow-y: auto;
  width: 40%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  // Medium devices (tablets, between 768px and 992px)
  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
  }

  // Small devices (less than 767px)
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.i-PageSide-leftContent {
  flex: 1;
}

.i-PageSide-right {
  width: 60%;

  // Medium devices (tablets, between 768px and 992px)
  @media screen and (min-width: 768px) and (max-width: 991px) {
    display: none;
  }

  // Small devices (less than 767px)
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.i-Button-element.i-Form_Button-reset[aria-disabled='true'] {
  display: none;
}

.i-Button-element.i-Form_Button-reset.is-Spinning {
  display: flex;
}

.i-Button-element.i-Button-elementAlign {
  float: right;
  margin-top: 3.5rem;
}

.idp-App button {
  max-height: 40px;
  height: 40px;
  padding: 0 2rem;
}

// centered page layout
.i-CenteredPageContent {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}