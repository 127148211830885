
// ---------------------
//   TYPOGRAPHY COLORS
// ---------------------
$text-dark: #3d4852;
$text-light: #606f7b;
$text-placeholder: #8795a1;

// ---------------------
//    BRAND COLORS
// ---------------------
$brand-blue: #00b2d5;
$brand-blue-light: #80d7e9;
$brand-blue-light-ultra: #e3f5f9;

$brand-navy: #2a455d;
$brand-navy-dark: #142636;
$brand-navy-dark-ultra: #101e2c;

$brand-yellow: #fbb513;
$brand-yellow-light: #fcc442;
$brand-yellow-light-ultra: #fdd371;

$brand-red: #e61c28;
$brand-red-light: #eb503e;
$brand-red-light-ultra: #ef7960;

// ---------------------
//  UI COLORS
// ---------------------
$ui-red-light: #ffdede; //Red Light
$ui-red-button: #950b13;
$ui-red: #cc4149; //Red
$ui-red-dark: #950b13; //Red Dark
$ui-red-light-dark: #a43f40; // Red Light Dark

$ui-green-light: #daf0e2; //Green Light
$ui-green: #159758; //Green
$ui-green-dark: #214b29; //Green Dark

$ui-blue-light: #daeeff; //Blue Light
$ui-blue: #3778b0; //Blue
$ui-blue-dark: #0c3957; //Blue Dark

$ui-orange-light: #fff5da; //Orange Light
$ui-orange: #ff9900; //Orange
$ui-orange-dark: #603f11; //Orange Dark

$ui-gray-darkest: #4c4c4c; //Darker Gray
$ui-gray-dark: #d9e0e6; //Dark Gray
$ui-gray: #f0f2f5; //Gray
$ui-gray-light: #efefef; //Background
$ui-gray-lightest: #f8f8f8; //Lighter Background

/*------------------------------------------------------
    DISABLED COLORS
-------------------------------------------------------*/
$disabled-default: $ui-gray-dark;
$disabled-highlight: $ui-gray;
$disabled-text: $text-placeholder;

/*------------------------------------------------------
     FOCUS OUTLINE
-------------------------------------------------------*/
$focus-outline: #ffe799;

/*------------------------------------------------------
    BORDER COLORS
-------------------------------------------------------*/
$border-default: $ui-gray-dark; // default border
$border-hover: #b8c2cc; // hover border

// input borders
$border-input-default: #b8c2cc; // default input border
$border-input-hover: $text-light; // hovered input border
$border-input-focus: $ui-blue; // focused input border
$border-input-disabled: $ui-gray-dark;

/*------------------------------------------------------
    ICON COLORS
-------------------------------------------------------*/
$icon-default: $text-placeholder;
$icon-hover: $text-light;

// ---------------------
//    PRIMARY PALETTE
// ---------------------
$primary-light: #daeeff;
$primary-medium: #008fc7;
$primary-solid: #247bab;
$primary-strong: #395f96;
$primary-dark: #0c3957;

// ---------------------
//    NEUTRAL PALETTE
// ---------------------
$neutral-lighter: #fcfcfc;
$neutral-light: #f8f8f8;
$neutral-medium: #efefef;
$neutral-solid: #d6d6d6;
$neutral-base: #a7a7a7;
$neutral-strong: #6e6e6e;
$neutral-stronger: #757575;
$neutral-dark: #4c4c4c;

// ---------------------
//   UTILITY PALETTE
// ---------------------
$white: #fff;
$black: #000;

// ---------------------
//    SHADOW PALETTE
// ---------------------
$box-shadow-light: 0px 3px 6px rgba(61, 72, 82, 0.06), 0px 1px 3px rgba(61, 72, 82, 0.06);
$box-shadow-medium: 0px 5px 10px rgba(61, 72, 82, 0.12), 0px 1px 3px rgba(0, 0, 61, 0.05);
$box-shadow-strong: 0px 5px 15px rgba(61, 72, 82, 0.18), 0px 6px 4px rgba(61, 72, 82, 0.04),
  0px 0px 8px rgba(61, 72, 82, 0.08);

/*------------------------------------------------------
    DATA INPUT VARIABLES AND MIXINS
-------------------------------------------------------*/
$data-input-maxWidth: 355px;
$data-input-marginBottom: 1.5rem;

// Label styling for all inputs
@mixin inputLabel() {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 6px;
  width: max-content;
  cursor: pointer;

  &-disabled {
    color: $text-light;
    cursor: not-allowed;
  }
}


/*------------------------------------------------------
	OBJECTS
-------------------------------------------------------*/
@import 'objects/Alert/Alert';
@import 'objects/Button/Button';
@import 'objects/Form/Form';
@import 'objects/Icon/Icon';
@import 'objects/Input/Input';
@import 'objects/Typography/Typography';

/*------------------------------------------------------
	ABTRACTS
-------------------------------------------------------*/
@import 'abtracts/variables-icons';