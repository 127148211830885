@import "../../styles/variables";

.i-Form_Message {
	display: flex;
	align-items: flex-start;
    border: thin solid #e0e0e0;
    box-sizing: border-box;
    padding: 1rem;
    transition: border 0.2s linear 0s, background-color 0.2s linear 0s, opacity 0.2s linear 0s;
    width: 100%;
    border-radius: 0.1875rem;
	font-size: 0.875rem;
	margin-top: 1rem;
	&.is-Hidden {
		display: none;
	}
	&.has-Error {
		border: thin solid $ui-red-dark;
		background-color: $ui-red-light;
	}
	&.has-Valid {
		border: thin solid $ui-green-dark;
		background-color: $ui-green-light;
	}
	// OVERRIDE - When the parent has some sort of state, adjust
	[class*="has-"] > & {
		background-repeat: no-repeat;
		background-position: center right 0.5rem;
		background-size: 1rem 1rem;
		padding: 0 2.8rem 0 .5rem
	}
}
// Note
.i-Note_Icon {
	margin-right: 1rem;
	line-height: 1;
	max-width: 1rem;
	max-height: 1rem;
}
.i-Note_Text {
	text-align: left;
	hyphens: auto;
	word-break: break-word;
}